import React from 'react';
import image1 from 'assets/HFCL.png';
import image2 from 'assets/LAMATA.png';
import image3 from 'assets/LOOP21.png';
import image4 from 'assets/N28.png';
import image5 from 'assets/Nimbus_Logo.png';
import image6 from 'assets/sterling-logo-balanced.png';
import { Bounce } from 'react-reveal';

export const partners = [image4, image3, image1];
export const partners2 = [image2, image6, image5];

const PartnerSection = () => {
  return (
    <div className="bg-[#fdfaff]  lg:p-20 pt-48  px-5 py-8  font-satoshi ">
      <p className="text-center text-[20px] font-bold text-[#555E67] mb-3 lg:mt-16 ">
        PARTNERS{' '}
      </p>
      <h6 className="text-center text-[#555E67]">
        Our Trusted partners worldwide{' '}
      </h6>

      <div className="lg:mt-14 mt-10 overflow-x-hidden">
        <Bounce right cascade>
          <div className="mx-auto flex w-[45%] lg:gap-x-10 gap-x-5 justify-center">
            {partners.map((item, i) => (
              <img
                key={i}
                src={item}
                alt="logo"
                className="hover:opacity-40 lg:h-[50px]  h-[30px] object-contain"
              />
            ))}{' '}
          </div>
          <div className="mx-auto flex w-[45%] mt-8 gap-x-10 justify-center">
            {partners2.map((item, i) => (
              <img
                key={i}
                src={item}
                alt="logo"
                className="hover:opacity-40 h-[50px]"
              />
            ))}
          </div>
        </Bounce>
      </div>
    </div>
  );
};

export default PartnerSection;
