import { Navigation, Parallax, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const AboutCarousel = () => {
  return (
    <div
      id="about"
      className="bg-about  bg-no-repeat bg-cover bg-[#fdfaff] bg-left-top"
    >
      <p className="text-center uppercase font-bold pt-12 mb-2 text-[20px] text-[#555E67]">
        ABOUT US{' '}
      </p>
      <h6 className="text-center text-[#555E67] text-[16px] lg:mb-10">
        Exploring Our Work{' '}
      </h6>
      <Swiper
        style={{
          '--swiper-navigation-color': '#222',
          '--swiper-pagination-color': '#EB4178',
        }}
        speed={600}
        parallax={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
        }}
        navigation={true}
        modules={[Parallax, Pagination, Navigation, Autoplay]}
        className="mySwiper text-[#222]  p-[20px]"
      >
        <SwiperSlide className=" mx-auto p-[50px] text-center">
          <div className="title ">What is Wave5Wireless®?</div>
          <div className="text w-full mx-auto">
            <p className=" text-center ">
              Wave5Wireless is a technology company that re-imagines the
              distribution and monetization of services beyond multi-play
              bundles when superfast internet access is the new normal.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className=" lg:px-[50px] p-[50px] text-center">
          <div className="title">What is Wave5Wireless®?</div>
          <div className="text w-full mx-auto">
            <p className=" text-center ">
              Wave5 Wireless is a robust and ubiquitous
              Infrastructure-as-a-Service (IaaS) built on a mass market last
              mile technology that delivers pipelines and protocols for the
              distribution of various utilities and services to the West African
              Public.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className=" lg:px-[50px] p-[50px] text-center">
          <div className="title">What is Wave5Wireless®?</div>
          <div className="text w-full mx-auto">
            <p className=" text-center ">
              Wave5Wireless is a value aggregator for different consumer
              segments being delivered over an almost ubiquitous network
              underpinned by the most sophisticated SaaS.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className=" lg:px-[50px] p-[50px] text-center">
          <div className="title">
            The Wave5Wireless® Business in a Nutshell{' '}
          </div>
          <div className="text w-full mx-auto">
            <p className=" text-center ">
              Bundling of services as utilities and not as premium add-ons
              delivered to the West African Public over a ubiquitous social
              class-agnostic infrastructure.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="mb-20" />
    </div>
  );
};

export default AboutCarousel;
