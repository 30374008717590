import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import img1 from 'assets/Frame 15(2).png';
import img2 from 'assets/Frame 15(3).png';
import img3 from 'assets/Frame 15(4).png';
import Button from 'components/common/button';
import { Link } from 'react-router-dom';

const Carousel = () => {
  const [activePosition, setActivePosition] = useState(1);

  const roll = (direction) => {
    const carousel = document.querySelector('.carousel');
    const items = document.querySelectorAll('.item');

    carousel.classList.add(`moving-${direction}`);

    items.forEach((item) => {
      const startPosition = parseInt(item.getAttribute('data-position'));
      let endPosition;

      if (direction === 'right') {
        endPosition = startPosition === 3 ? 1 : startPosition + 1;
      }
      if (direction === 'left') {
        endPosition = startPosition === 1 ? 3 : startPosition - 1;
      }

      item.style.zIndex = endPosition === 3 ? 1 : -1;

      item.setAttribute('data-position', endPosition);
      item.addEventListener(
        'transitionend',
        () => {
          carousel.classList.remove(`moving-${direction}`);
        },
        false
      );

      setActivePosition(endPosition);
    });
  };

  useEffect(() => {
    const leftButton = document.querySelector('.button--left');
    const rightButton = document.querySelector('.button--right');

    const handleButtonClick = (direction) => {
      roll(direction);
    };

    leftButton.addEventListener('click', () => handleButtonClick('left'));
    rightButton.addEventListener('click', () => handleButtonClick('right'));

    return () => {
      leftButton.removeEventListener('click', () => handleButtonClick('left'));
      rightButton.removeEventListener('click', () =>
        handleButtonClick('right')
      );
    };
  }, []);

  const handleDotClick = (index) => {
    const clickedPosition = index + 1;
    let direction = '';

    if (clickedPosition > activePosition) {
      direction = 'right';
    } else if (clickedPosition < activePosition) {
      direction = 'left';
    }

    if (direction) {
      roll(direction);
      setActivePosition(index);
    }
  };

  return (
    <div
      id="services"
      className="carousel-container lg:h-[850px] h-[500px] p-5 pt-10 lg:p-10 lg:pt-16 relative bg-[#fdfaff] "
    >
      <p className="text-center text-[20px] font-bold uppercase text-[#555E67] mb-3 ">
        Our Services{' '}
      </p>
      <h6 className="text-center text-[#555E67]">Discover what we provide </h6>
      <ul className="carousel ">
        {data.map((item, index) => {
          const position = index + 1;

          return (
            <li
              key={position}
              className={`item relative item--${position}`}
              data-position={position}
            >
              <div className="bg-[#F3F0FF] lg:p-5 p-2 relative lg:rounded-[20px] rounded-2xl">
                <img
                  src={item?.image}
                  alt="icon"
                  className="lg:h-[150px] object-contain mx-auto mb-3"
                />
                <p className="lg:text-[10px] text-[8px] text-[#000000] text-center my-2">
                  {item?.title}
                </p>
                <p className="text-[#585858] lg:text-[8px]  text-center text-[6px]  ">
                  {item?.subtitle}
                </p>
                <Link to={item?.link} target='_parent'>
                  <Button
                    className={
                      'w-fit lg:text-[10px] !text-[8px] !text-center bg-transparent justify-center border-[0.5px] border-[#5A3D8B] mt-4 mx-auto flex !text-[#5A3D8B] rounded-[40px]  lg:h-[25px] h-[18px] items-center !py-[1px] '
                    }
                  >
                    {item?.linkTitle}
                  </Button>
                </Link>
              </div>
            </li>
          );
        })}
      </ul>

      {/* Scroll indicator dots */}
      <div className="absolute lg:bottom-[-10px] bottom-[-85px] left-0 right-0 flex justify-center">
        {data.map((_, index) => (
          <span
            key={index}
            onClick={() => handleDotClick(index)}
            className={`h-[10px] w-[10px] rounded-full mx-[6px] cursor-pointer ${
              index + 1 === activePosition ? 'bg-[#EB4178]' : 'bg-gray-400'
            }`}
          ></span>
        ))}
      </div>

      <button className="absolute  button button--left">
        <Icon
          icon="iconamoon:arrow-left-2"
          className="lg:text-[50px] text-[30px]"
        />
      </button>

      <button className="absolute  button button--right">
        <Icon
          icon="iconamoon:arrow-right-2"
          className="lg:text-[50px] text-[30px]"
        />
      </button>
    </div>
  );
};

export default Carousel;

const data = [
  {
    image: img1,
    title: 'Atmospherewifi ',
    subtitle: `We connect millions of people using the combined power of 6G Wi-Fi and the African Sun. Everyday millions of people have access to free internet via Wi-Fi 6, the latest and most advanced radio technology powered by our proprietary power solution underpinned by the West African Sun.`,
    link: 'https://wifi.atmosphere.net.ng/',
    linkTitle: 'Register',
  },
  {
    image: img2,
    title: 'Atmosphere for Business',
    subtitle: `Atmosphere provides a single platform for managing marketing campaigns across its Wi-Fi network. It lets you quickly create, test and scale individual campaigns based on audience / business proximity, time of day and more. Advertise to people in your target audience`,
    link: 'https://www.atmosphere.ng/',
    linkTitle: 'Learn more',
  },
  {
    image: img3,
    title: 'MyNaija Market ',
    subtitle: `Turbo Charging the West Africa's informal market with free inventory and e-commerce, logistics and payments for the Big markets across West Africa. "Going to the market" just took on a new meaning. Your shopping experience is about to change forever!`,
    link: 'https://www.mynaijamarket.com/',
    linkTitle: 'Coming soon',
  },
];
