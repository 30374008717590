import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white px-10">
      <h1 className="text-4xl font-bold text-gray-700 mb-4">404</h1>
      <p className="text-lg text-gray-700 text-center mb-8">
        Oops! The page you're looking for doesn't exist.
      </p>
      <div className="mt-8">
        <Link
          to="/"
          className="inline-block px-6 py-[10px] text-white bg-gradient-to-b from-[#5b3f8b] to-[#5a3d8b] rounded-[10px] hover:bg-gradient-to-b hover:from-[#5a3d8b] hover:to-[#5b3f8b]"
        >
          Go back to home
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
