import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import emailjs from '@emailjs/browser';

import CustomInput from 'components/common/custominput';
import Button from 'components/common/button';

const validationSchema = Yup.object({
  email: Yup.string().email().required('Email is required'),
  name: Yup.string().required('Fullname is required'),
  phone: Yup.string().required('Phone no. is required'),
  message: Yup.string().required('Message is required'),
});

const ContactForm = () => {
  const initialValues = {
    email: '',
    name: '',
    phone: '',
    message: '',
  };

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values, { resetForm }) => {
    const { email, message, phone, name } = values;

    try {
      // const templateParams = {
      //   email,
      //   message,
      //   phone,
      //   name,
      // };

      // await emailjs.send(
      //   'service_o8ffo5b',
      //   'template_rcmj249',
      //   templateParams,
      //   'x56yMyCHRWxKS2Ed7'
      // );

      const url = 'https://api.mynaijamarket.ng/notification/sendEmail';

      const templateParams = {
        toEmail: 'hello@atmosphere.ng',
        fromEmail: 'hello@atmosphere.ng',
        subject: `Email from Wave5Wireless Website Contact Page`,
        html: `<p>Name: ${name}</p> <p>Email:${email}</p> <p>Phone Number: ${phone}<p/> <p>Message:${message}</p>`,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(templateParams),
      });

      if (!response.ok) {
        setErrorMessage('Oops, Something went wrong');
        setTimeout(() => {
          setErrorMessage();
        }, 3000);
      } else {
        const responseData = await response.json();
        console.log('Response Data:', responseData);

        setSuccessMessage(
          `Your message has been submitted successfully. We'll be in touch shortly.!`
        );
        setTimeout(() => {
          setSuccessMessage();
        }, 3000);

        resetForm(); // Reset the form on successful submission
      }
    } catch (error) {
      setErrorMessage('Oops, Something went wrong');
      setTimeout(() => {
        setErrorMessage();
      }, 3000);
    }
  };

  return (
    <div>
      {errorMessage && <p className="text-[red] mt-3">{errorMessage}</p>}
      {successMessage && <p className="text-[green] mt-3">{successMessage}</p>}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CustomInput id="name" name="name" type="text" placeholder="Name" />
            <CustomInput
              id="email"
              name="email"
              type="email"
              placeholder="Email"
            />
            <CustomInput
              id="phone"
              name="phone"
              type="digit"
              placeholder="Phone no."
            />
            <CustomInput
              id="message"
              name="message"
              type="text"
              placeholder="Message"
              as={'textarea'}
            />
            <div className=" gap-8 mt-8">
              <Button
                type="submit"
                className={'w-fit rounded-[24px] px-12 bg-[#5A3D8B] '}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
