import React from 'react';
import { Field, ErrorMessage } from 'formik';

const CustomInput = ({ id, name, placeholder, type, as }) => {
  return (
    <div>
      <div className="relative">
        <Field
          type={type}
          id={id}
          name={name}
          className="lg:w-[70%] w-full border-[1px] py-3 px-4 shadow-card mt-4 rounded-[20px] focus:outline-none"
          placeholder={placeholder}
          as={as}
          rows={4}
        />
      </div>

      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-xs mt-1 pl-2"
      />
    </div>
  );
};

export default CustomInput;
