import React from 'react';
import logo from 'assets/LOGO 2_4 1.png';
import logoIcon from 'assets/LOGO 1_PNG 4 1.png';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#5A3D8B] relative">
      <img
        src={logoIcon}
        height={300}
        width={200}
        className="h-[180px] w-auto absolute left-0 bottom-0"
        alt="logo"
      />

      <div className="lg:flex items-start lg:px-32  px-5 py-8 justify-between container mx-auto">
        <img
          src={logo}
          height={34}
          width={114}
          className="h-[34px] w-auto lg:mx-0 mx-auto lg:mb-0 mb-12"
          alt="logo"
        />

        <div className="text-center lg:text-left lg:mb-0 mb-12">
          <h6 className="text-[#EB4178] font-bold">Services</h6>
          <ul className="mt-4 grid gap-y-4 text-[13px]  text-white ">
            {services.map((service) => (
              <li key={service.link}>
                <Link
                  to={service.link}
                  target="_blank"
                  className=" hover:underline underline-offset-4"
                >
                  {service.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="text-center lg:text-left lg:mb-0 mb-12">
          <h6 className="text-[#EB4178] font-bold">Contact Us</h6>
          <div className="mt-4 grid gap-y-4 text-[13px]  text-white ">
            <p>
              Address: 17/19 Badejo Kalesanwo Street,
              <br /> Matori, Lagos, Nigeria.
            </p>
            <p>Phone: 018880123</p>
          </div>
        </div>
        <div className="text-center lg:text-left lg:mb-0 mb-12">
          <h6 className="text-[#EB4178] font-bold">Follow Us</h6>
          <div className="mt-4 flex gap-x-4 lg:justify-start justify-center text-[13px] text-white ">
            {socialIcons.map((socialIcon, index) => (
              <Link key={index} to={socialIcon.link} target="_blank">
                <Icon icon={socialIcon.icon} height={socialIcon.height} />
              </Link>
            ))}
          </div>
        </div>
      </div>

      <hr className="border-[#31373D] mt-10" />
      <p className="py-6 text-white text-center text-sm">
        © {new Date().getFullYear()} Wave5Wireless. All rights reserved.
      </p>
    </footer>
  );
};

const services = [
  { name: 'Atmosphere Wi-Fi', link: 'https://wifi.atmosphere.net.ng/' },
  { name: 'Atmosphere For Business', link: 'https://www.atmosphere.ng/' },
  { name: 'MyNaija Market', link: 'https://mynaijamarket.com/' },
];

const socialIcons = [
  {
    icon: 'formkit:linkedin',
    link: 'https://www.linkedin.com/company/wave5wireless/',
    height: 22,
  },
  {
    icon: 'simple-icons:facebook',
    link: 'https://facebook.com/atmospherewifi',
    height: 20,
  },
  {
    icon: 'pajamas:twitter',
    link: 'https://twitter.com/Wave5W',
    height: 20,
  },
  // { icon: 'bi:instagram', height: 17 },
];

export default Footer;
