import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// import logo from 'assets/LOGO 1_PNG 2.png';
// import logo from 'assets/wave.jpg';
import logoIcon from 'assets/LOGO 1_PNG 4 1.png';
import logo from 'assets/LOGO 1 (1).png';
import { Link as ScrollLink } from 'react-scroll';
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react';

const Navbar = () => {
  const location = useLocation();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
  };

  const renderLinks = () => (
    <>
      {navItems?.map((item, i) => (
        <ScrollLink
          to={item?.path}
          smooth={true}
          duration={500}
          key={i}
          className={`${
            item?.path === location?.pathname
              ? 'text-[#d63d7d] font-[900]'
              : 'lg:text-[#000] text-white font-[500]'
          } lg:auto block lg:text-center text-lg cursor-pointer`}
          onClick={() => setIsMobileNavOpen(false)}
        >
          {item?.title}
        </ScrollLink>
      ))}
    </>
  );

  return (
    <>
      <div className="lg:flex items-center lg:px-24  px-5 py-8 justify-between container mx-auto">
        <div className="flex justify-between items-center">
          <img
            src={logo}
            height={108}
            width={114}
            className="h-[45px] w-auto"
            alt="logo"
          />
          {/* Mobile Navigation Toggle */}
          <div
            className="lg:hidden cursor-pointer"
            onClick={handleMobileNavToggle}
          >
            {/* Hamburger Icon */}
            <div className="w-6 h-px bg-black my-1"></div>
            <div className="w-6 h-px bg-black my-1"></div>
            <div className="w-6 h-px bg-black my-1"></div>
            <div className="w-6 h-px bg-black my-1"></div>
          </div>
        </div>
        <motion.div
          className={`lg:hidden  ${
            isMobileNavOpen ? 'fixed inset-0  z-50 bg-[#5A3D8B]  ' : 'hidden'
          }`}
          initial="closed"
          animate={isMobileNavOpen ? 'open' : 'closed'}
          variants={variants}
        >
          <div className="flex justify-between items-center px-10 mt-8">
            <div />
            {/* <img
              src={logo}
              height={108}
              width={114}
              className="h-[45px] w-auto"
              alt="logo"
            /> */}
            {/* Mobile Navigation Close Toggle */}
            <div
              className="lg:hidden cursor-pointer"
              onClick={handleMobileNavToggle}
            >
              <Icon
                icon={'ooui:close'}
                className="text-[#EB4178]"
                height={25}
              />
            </div>
          </div>
          <div className="gap-8 flex flex-col mt-14 px-10">
            {' '}
            {renderLinks()}
          </div>
          <ScrollLink
            to={'newsletter'}
            smooth={true}
            duration={500}
            className={`bg-[#fff]  flex w-fit mt-8 text-sm text-[#5A3D8B] py-2 px-5 ml-8 font-semibold  rounded-3xl cursor-pointer`}
            onClick={() => setIsMobileNavOpen(false)}
          >
            Contact Us
          </ScrollLink>

          <img
            src={logoIcon}
            height={300}
            width={200}
            className="h-[180px] w-auto absolute left-0 bottom-0"
            alt="logo"
          />
        </motion.div>

        <div className="lg:flex hidden items-center gap-16 font-satoshi">
          {renderLinks()}
        </div>
        <ScrollLink
          to={'newsletter'}
          smooth={true}
          duration={500}
          className={`bg-[#5A3D8B] lg:block hidden text-sm text-white py-2 px-5 rounded-3xl cursor-pointer`}
          onClick={() => setIsMobileNavOpen(false)}
        >
          Contact Us
        </ScrollLink>
      </div>
    </>
  );
};

const navItems = [
  { title: 'Home', path: '/' },
  { title: 'About', path: 'about' },
  { title: 'Services', path: 'services' },
];

export default Navbar;
