import React from 'react';

const Button = ({ children, className }) => {
  return (
    <button
      // className={`inline-block px-6 text-lg py-[10px] text-white rounded-[10px] bg-[#d63d7d]  ${className}`}
      className={`inline-block px-6 text-lg py-[10px] text-white rounded-[10px] bg-[#5A3D8B] hover:opacity-80  ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
