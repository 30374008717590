import Navbar from 'components/navbar';
import React from 'react';
import Newsletter from 'components/newsletter';
import AboutCarousel from 'common/AboutCarousel';
import bgplatform from 'assets/bgplatform.png';
import Footer from 'components/footer';
import PartnerSection from 'components/partners';
import ServicesSection from 'components/about/services';

const Home = () => {
  return (
    <>
      <div className="bg-hero1  bg-no-repeat bg-cover bg-[#fdfaff] bg-left-top">
        <Navbar />

        <div className="lg:w-[45%] px-5 lg:px-0 mx-auto lg:text-center mt-12 pb-6">
          <p className="text-[#1D1E20] font-bold lg:text-[56px] text-[32px] lg:leading-[70px]">
            The Business of Hyper Connectivity
          </p>
          <p className="lg:text-[18px] text-base text-[#31373D] mt-6 leading-8">
            Wave5 Wireless is on a mission to accelerate the hyper connection of
            consumer clusters in Sub Saharan Africa and to re-imagine the
            creation, provision, distribution and monetization of value for the
            same. We believe the biggest and the future of markets are consumer
            networks.
          </p>
          <img
            src={bgplatform}
            height={108}
            width={114}
            className="lg:h-[419px] object-contain w-auto mt-6 mx-auto"
            alt="logo"
          />
        </div>
      </div>

      <AboutCarousel />
      <ServicesSection />
      <PartnerSection />
      <Newsletter />

      <Footer />
    </>
  );
};

export default Home;
