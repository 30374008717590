import React from 'react';
import ContactForm from './form';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const Newsletter = () => {
  const socialIcons = [
    {
      icon: 'formkit:linkedin',
      link: 'https://www.linkedin.com/company/wave5wireless/',
      height: 22,
    },
    {
      icon: 'simple-icons:facebook',
      link: 'https://facebook.com/atmospherewifi',
      height: 20,
    },
    {
      icon: 'pajamas:twitter',
      link: 'https://twitter.com/Wave5W',
      height: 20,
    },
    // { icon: 'bi:instagram', height: 17 },
  ];

  return (
    <div
      id="newsletter"
      className={`bg-[#fdfaff] lg:px-24  px-5 lg:py-28 pt-10 pb-20  `}
    >
      <div className=" text-center font-satoshi lg:w-[55%] mx-auto">
        <p className="text-center text-[20px] font-bold text-[#555E67] mb-3">
          FOLLOW OUR WIRELESS NETWORK JOURNEY{' '}
        </p>
        <h6 className="text-center text-[#555E67]">
          Join us on our journey to redefine wireless connectivity and
          experience the future of seamless connectivity. For more information,
          product details, and special promotions, please contact us today.{' '}
        </h6>
        <div className="flex justify-center gap-x-4 text-[13px] text-[#EB4178]  mt-6">
          {socialIcons.map((socialIcon, index) => (
            <Link key={index} to={socialIcon.link} target="_blank">
              <Icon icon={socialIcon.icon} height={socialIcon.height} />
            </Link>
          ))}
        </div>

        <p className="text-center text-[20px] uppercase font-bold text-[#555E67] mb-2 mt-20">
          Let&apos;s call you back{' '}
        </p>
        <h6 className="text-center text-[#555E67]">
          Just say hello and we&apos;ll give you a call{' '}
        </h6>
        <ContactForm />
      </div>{' '}
    </div>
  );
};

export default Newsletter;
